<template>
  <div class="box">
    <el-header style="min-width: 1200px">
      <div class="top-logo">
        <img :src="imgConfig" alt="" width="183" height="70" style="float: left" />
        <ul id="header_menu" class="menu">
          <li>
            <span class="module-tit suninfo" style="font-weight: bold; font-size: 24px; color: white">找回密码</span>
          </li>
        </ul>
      </div>
      <div class="top_r">
        <span>
          <a @click="loginBtn" class="suninfo">登录</a>
        </span>
      </div>
    </el-header>
    <el-container>
      <div class="wrap_">
        <div class="wrapBox">
          <legend class="legend">请输入要找回的账号</legend>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm formPwd">
            <el-form-item label="用户名：" prop="name">
              <el-input size="mini" v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="验证方式：" prop="region">
              <el-select size="mini" v-model="ruleForm.region" placeholder="请选择" @change="changeType">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="邮箱：" prop="email" v-if="ruleForm.region == 1">
              <el-input size="mini" v-model="ruleForm.email"></el-input>
            </el-form-item>
            <el-form-item label="电话：" prop="phone" v-else>
              <el-input size="mini" v-model="ruleForm.phone"></el-input>
            </el-form-item>
            <!-- prop="code"   :rules=" [{ required: true, message: '请输入验证码', trigger: 'blur' },{ min:6, max: 6, message: '长度为6个字符', trigger: 'blur' }]"-->
            <el-form-item label="验证码：">
              <el-input size="mini" v-model="ruleForm.code" maxlength="6"></el-input>
              <el-button size="mini" style="margin-left: 10px" type="primary" @click="resetForm('ruleForm')">获取验证码</el-button>
            </el-form-item>
            <el-form-item>
              <el-button size="mini" type="info" v-if="ruleForm.code.length==0" disabled>下一步</el-button>
              <el-button size="mini" type="primary" v-else @click="submitForm('ruleForm')">下一步</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-container>
    <!-- 提示消息框 -->
    <dialogInfo :dialogInfo="visibaelFlag" @handleClose="handleCloseInfo" @determine="determineInfo" :titleInfo="titleInfo" :width="'240px'" />
    <!-- 重置密码弹框 -->
    <elDialog :title="'修改密码'" :width="'400px'" :num="'200px'" :btnTitle="'提交'" :dialogVisible="statusVisiblemm" @handleClose="handleClose" @heightCustom="heightCustomRest" :flagbtn="true" :determineBtn="true" @determine="determineRest">
      <div style="text-align: center;height: 30px;padding:5px 0px;"><span class="error errorInfo" v-show="restWords" style="color: red;">{{rest_words}}</span></div>
      <el-form style="height: 90px; margin-top: 10px;" :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
        <!-- <el-form-item label="旧密码：" prop="oldPassword">
          <el-input type="password" @input="inputRestWords" v-model="form.oldPassword" clearable size="mini" class="passInputs"></el-input>
        </el-form-item> -->
        <el-form-item label="新密码：" prop="newPassword1">
          <el-input type="password" @input="inputRestWords" v-model="form.newPassword1" clearable size="mini" class="passInputs"></el-input>
        </el-form-item>
        <el-form-item label="确认密码：" prop="newPassword2">
          <el-input v-model="form.newPassword2" @input="inputRestWords" clearable type="password" size="mini" class="passInputs"></el-input>
        </el-form-item>
      </el-form>
    </elDialog>
    <el-footer>{{this.copyText}}</el-footer>
  </div>
</template>
<script>
import utils from '../utils/index.js'
import elDialog from '@/components/elDialog' //详情弹框
import dialogInfo from '@/components/promptMessage/index' //提示消息框
export default {
  components: { dialogInfo, elDialog },
  data() {
    // 邮箱验证
    var validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请正确填写邮箱'))
      } else {
        if (value !== '') {
          // var reg =/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
          var reg =/^\w{3,15}\@\w+\.[a-z]{2,3}$/
          if (!reg.test(value)) {
            callback(new Error('请输入有效的邮箱'))
          }
        }
        callback()
      }
    }
    var validateMobilePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('电话不可为空'))
      } else {
        if (value !== '') {
          var reg = /^1[3456789]\d{9}$/
          if (!reg.test(value)) {
            callback(new Error('请输入有效的手机号码'))
          }
        }
        callback()
      }
    }

    return {
      copyText: infoConfig.copyright, //底部版权归属
      numRest: '174px',
      restWords: false,
      rest_words: '', //重置密码提示语
      statusVisiblemm: false, //修改密码谈框默认关闭
      titleInfo: '',
      visibaelFlag: false, //提示消息弹框默认不显示
      imgConfig:
        vueConfig.jqUrl.slice(0, vueConfig.jqUrl.length - 1) +
        '/msp/protected/config/logo/logo.jpg',
      ruleForm: {
        name: '',
        region: '1',
        email: '',
        phone: '',
        code: ''
      },
      options: [
        {
          value: '1',
          label: '邮箱'
        },
        {
          value: '2',
          label: '电话'
        }
      ],
      form: {
        newPassword1: '',
        newPassword2: ''
      },
      sendType: 'email', //发送方式
      sendValue: '', //发送的值
      rules: {
        name: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        email: [{ required: true, validator: validateEmail, trigger: 'blur' }],
        phone: [
          { required: true, validator: validateMobilePhone, trigger: 'blur' }
        ],

        newPassword1: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 8,
            max: 32,
            message: '8~32位，至少包含数字、字母、符号两种元素。',
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (
                /(?!\d+$)(?!^[a-zA-Z]+$)(?!^[_#@]+$).{8,32}/.test(value) ==
                false
              ) {
                callback(
                  new Error('8~32位，至少包含数字、字母、符号两种元素。')
                )
              }  else if (value == '') {
                callback(new Error('请输入密码'))
              } else {
                callback()
              }
            },
            trigger: 'change' //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          }
        ],
        newPassword2: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          {
            min: 8,
            max: 32,
            message: '8~32位，至少包含数字、字母、符号两种元素。',
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入确认密码'))
              } else if (
                /(?!\d+$)(?!^[a-zA-Z]+$)(?!^[_#@]+$).{8,32}/.test(value) ==
                false
              ) {
                callback(
                  new Error('8~32位，至少包含数字、字母、符号两种元素。')
                )
              } else if (
                this.form.newPassword1 !== '' &&
                value !== this.form.newPassword1
              ) {
                callback(new Error('两次输入密码不一致!'))
              } else {
                callback()
              }
            },
            trigger: 'blur' //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          }
        ]
      }
    }
  },
  methods: {
    loginBtn() {
      this.$router.push('/login')
    },
    changeType(value) {
      console.log(value, 'value')
      if (value == 1) {
        this.sendType = 'email'
        this.$refs['ruleForm'].clearValidate([ 'phone']);
      } else if (value == 2) {
        this.sendType = 'phone'
        this.$refs['ruleForm'].clearValidate([ 'email']);
      }
    },
    // 校验框重置
    resetForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getCode() //获取验证码
        } else {
          console.log('error submit!!')
          return false
        }
      })
      // this.$refs[formName].resetFields()
    },
    //获取验证码
    async getCode() {
      console.log(this.sendType, '  this.sendType')
      var params = {
        loginName: this.ruleForm.name,
        type: this.sendType,
        value: this.ruleForm.email ? this.ruleForm.email : this.ruleForm.phone
      }
      const res = await this.$axios.post(
        '/httpServe/forgetPwd/getVerifyCode',
        params,
        true
      )
      if (res.code == 200) {
        if (res.data == 1) {
          this.$message({
            message: '获取验证码成功',
            type: 'success',
            offset: 100
          })
        } else if (res.data == -1) {
          this.$message({
            message: '验证码未失效',
            type: 'error',
            offset: 100
          })
        }
         else if (res.data == -3) {
          this.$message({
            message: '手机号/邮箱输入错误',
            type: 'error',
            offset: 100
          })
        }
        else if (res.data == -4) {
          this.$message({
            message: '验证次数超过限制，请稍后再试',
            type: 'error',
            offset: 100
          })
        }
      }
      console.log(res, 'sendType')
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.user()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    //校验用户
    async user() {
      var params = {
        loginName: this.ruleForm.name,
        type: this.sendType,
        value: this.ruleForm.email ? this.ruleForm.email : this.ruleForm.phone,
        verifyCode: this.ruleForm.code
      }
      const res = await this.$axios.post(
        '/httpServe/forgetPwd/verifyUser',
        params,
        true
      )
      if (res.code == 200) {
        if (res.data == 1) {
          this.$message({
            message: "校验用户验证码成功",
            type: 'success',
            offset: 100
          })
          this.statusVisiblemm = true
        }
        if (res.data == -1) {
          this.$message({
            message: "获取验证码失败",
            type: 'error',
            offset: 100
          })
        }
        if (res.data == -2) {
          this.$message({
            message: "校验用户验证码失败",
            type: 'error',
            offset: 100
          })
        }
        if (res.data == -3) {
          this.$message({
            message: "校验用户验证码失败",
            type: 'error',
            offset: 100
          })
        }
        if (res.data == -4) {
          this.$message({
            message: '验证次数超过限制，请稍后再试',
            type: 'error',
            offset: 100
          })
        }
      }
      console.log(res, '校验用户')
    },
    //提示信息
    handleCloseInfo() {
      this.visibaelFlag = false //提示消息弹框关闭
    },
    //提示信息
    determineInfo() {
      this.visibaelFlag = false
    },
    determineRest() {
      this.submit() //重置密码
    },
    //用户输入时提示语不显示
    input() {
      this.markedWords = false
    },
    inputRestWords() {
      this.restWords = false
    },
    submit(form){
      this.$refs.form.validate((valid) => {
        if (valid) {
          //验证通过保存数据
          this.resetPass()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async resetPass() {
      var params = {
        loginName: this.ruleForm.name,
        newPassWord: utils.common.encrypt(
          this.form.newPassword1,
          'SM4',
          $.getSecretKey(this.ruleForm.name) //基于用户名的密钥
        )
      }
      const res = await this.$axios.post(
        '/httpServe/forgetPwd/changePwd',
        params,
        true
      )
      if (res.code == 200) {
        if (res.data == 1) {
          this.$message({
            message: "修改密码成功",
            type: 'success',
            offset: 100
          })
         this.$router.push('/login')
        }
        if (res.data == -3) {
          this.$message({
            message: "校验用户验证码失败",
            type: 'error',
            offset: 100
          })
        }
      }
      console.log(res, '重置密码')
    },
    heightCustomRest() {
      this.height.height = this.numRest
    },
    // 弹框点击关闭事件
    handleClose() {
      this.statusVisiblemm = false //修改密码
      this.form={}
      this.$refs['form'].resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
/* 头部 */
.el-header {
  height: 70px !important;
  width: 100vw;
  background: #1e89e5;
  display: flex;
  justify-content: space-between;
  padding: 0 !important;
}
.top-logo {
  height: 70px;
  width: 400px;
}
.el-container {
  width: 100vw;
  height: calc(100vh - 100px);
  background-color: #eaedf2;
  position: relative;
  .wrap_ {
    height: 428px;
    width: 600px;
    padding: 20px;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    border-radius: 8px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.wrapBox {
  padding: 5px;
  .legend {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: inherit;
    color: #333;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 10px;
  }
  .formPwd {
    padding-left: 50px;
    .el-input {
      width: 193px;
    }
    .el-form-item {
      margin-bottom: 10px;
    }
  }
}

/* 底部 */
.el-footer {
  height: 30px !important;
  background: #1e89e5;
  text-align: center;
  line-height: 30px;
  border-top: 1px solid #abdba5;
  color: #ffffff;
  font-size: 12px;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
}
.menu {
  float: left;
  margin-left: 30px;
  li {
    list-style-type: none;
    font-size: 13px;
    color: #fff;
    text-align: center;
    margin-top: 30px;
  }
}
.top_r {
  width: 60px;
  height: 35px;

  z-index: 9999;
  background: #0e78d5;
  padding-left: 15px;
  border-radius: 0 0 10px 10px;
  margin-right: 15px;
}
.top_r a {
  height: 35px;
  line-height: 35px;
  text-align: center;
  display: block;
  float: left;
  color: #eaeae4;
  text-decoration: none;
  cursor: pointer;
  margin: 0 auto;
  margin-right: 10px;
}
</style>
